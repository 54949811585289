body {
  margin: 0;
  font-family: 'Archivo', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

  /* !important is needed sometimes */
  ::-webkit-scrollbar {
    width: 6px !important;
 }

 /* Track */
::-webkit-scrollbar-track {
   -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3) !important;
   background: #2e2e2e;
   /* -webkit-border-radius: 10px !important;
   border-radius: 10px !important; */
 }

 /* Handle */
 ::-webkit-scrollbar-thumb {
   /* -webkit-border-radius: 10px !important; */
   /* border-radius: 10px !important; */
   background: #c1feaf !important; 
   -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5) !important; 

 }
 ::-webkit-scrollbar-thumb:window-inactive {
   background: #c1feaf !important; 
 }

/* width
::-webkit-scrollbar {
  width: 10px;
  display: block;
} */

/* Track
::-webkit-scrollbar-track {
  background-color: #2e2e2e;
  opacity: 0;
} */
 
/* Handle
::-webkit-scrollbar-thumb {
  background: #c1feaf !important; 
} */

/* Handle on hover
::-webkit-scrollbar-thumb:hover {
  background: #b30000; 
} */



