

@media screen and (max-width: 899px) {
    .CreateEventForm {
        width: 331px;
    }

    .CreateEventScreen {
        width: 331px; 
        align-self: center;
        padding-top: 30px 
    }
}

@media screen and (min-width: 900px) {
    .CreateEventForm {
        width: 70%;
    }

    .CreateEventScreen {
        width: 70%; 
        align-self: center;
        padding-top: 30px 
    }
}