

.SetupUserHeader {
    color: #c1feaf;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    text-align: left;
}
.SetupUserDivider {
    /* background-color: #303030; */
    width: 100% !important;
    align-self: center;
}
.SetupUserInfoLabel {
    color: #829aff;
    font-size: 16px;
    font-weight: 400;
    text-align: left;
}
.SetupUserIconContainer {
    position: relative;
    width: 100px;
    height: 100px;
}
.SetupUserIcon {
    height: 100px;
    width: 100px;
    border-radius: 50%;
    border: 2px solid white;
}
.SetupUserIconPlaceholder {
    background-color: #ffffff;
    border-radius: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(4);
}