@media screen and (min-width: 1200px) {
  .connect-wallet {
    background-color: #1578f1;
    color: white;
    width: 150px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-size: 15px;
    font-weight: bold;
    border: 1px solid transparent;
    border-radius: 50px;
    padding: 0.8rem 1rem;
    cursor: pointer;
    margin: 1rem 10rem;
  }
  .ConnectWalletContainer {
    text-transform: capitalize !important;
    width: 100% !important;
    background-color: #2a4fff !important;
    color: #f1f1f1 !important;
    padding: 10px 0px !important;
    font-size: 20px !important;
    border-radius: 50px !important;
  }
}

@media screen and (min-width: 900px) and (max-width: 1199px) {
  .connect-wallet {
    background-color: #1578f1;
    color: white;
    width: 150px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-size: 15px;
    font-weight: bold;
    border: 1px solid transparent;
    border-radius: 50px;
    padding: 0.8rem 1rem;
    cursor: pointer;
    margin: 1rem 10rem;
  }
  .ConnectWalletContainer {
    text-transform: capitalize !important;
    width: 100% !important;
    background-color: #2a4fff !important;
    color: #f1f1f1 !important;
    padding: 10px 0px !important;
    font-size: 20px !important;
    border-radius: 50px !important;
  }
}

@media screen and (max-width: 899px) {
  .connect-wallet {
    background-color: #1578f1;
    color: white;
    width: 150px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-size: 15px;
    font-weight: bold;
    border: 1px solid transparent;
    border-radius: 50px;
    padding: 0.8rem 1rem;
    cursor: pointer;
    margin: 1rem 10rem;
  }
  .ConnectWalletContainer {
    text-transform: capitalize !important;
    width: 100% !important;
    background-color: #2a4fff !important;
    color: #f1f1f1 !important;
    border-radius: 50px !important;
  }
}

.connect-wallet-loading {
  cursor: none;
}



.ModalContainer {
  width: 331px !important;
  position: relative !important;
  left: 50% !important;
  top: 50% !important;
  transform: translate(-50%, -50%) !important;
  align-items: center !important;
}
.ModalCloseIcon {
  align-self: flex-end !important;
  color: #f1f1f1 !important;
  margin-bottom: 10px !important;
}
.ModalHeader {
  font-size: 30px;
  color: #f1f1f1;
}
.ModalBody {
  color: #f1f1f1;
}
.ModalNewsletterHeader {
  font-size: 18px;
  color: rgba(193, 254, 175, 1);
  text-align: left;
}
.ModalNewsletterInput {
  padding: 10px;
  color: rgba(162, 163, 169, 1);
}
.ModalNewsletterInput .ModalNewsletterInputText {
  color: rgba(162, 163, 169, 1);
}
.ModalNewsletterInput .ModalNewsletterMailIcon {
  padding-right: 20px;
  color: #f1f1f1;
}
.ModalNewsletterInput .ModalNewsletterButton {
  text-transform: capitalize;
  color: rgba(193, 254, 175, 1);
  border-color: rgba(193, 254, 175, 1);
  border-radius: 10px;
  width: 100px;
}
