

.DynamicGoalHeaderContainer {
    justify-content: space-between;
    align-items: center;
    background-color: #00008f;
    padding: 16px;
    margin-bottom: 15px;
    margin-top: 15px;
    width: 100%;
    border-radius: 10px;
}
.DynamicGoalHeader {
    font-size: 18px;
    color: #99adeb;
}
.DynamicGoalIcon {
    color: #99adeb;
}

.StaticGoalHeaderContainer {
    justify-content: space-between;
    align-items: center;
    background-color: #113b02;
    padding: 16px;
    margin-bottom: 15px;
    margin-top: 15px;
    width: 100%;
    border-radius: 10px;
}
.StaticGoalHeader {
    font-size: 18px;
    color: #c1feaf;
}
.StaticGoalIcon {
    color: #c1feaf;
}

.SpecificFundraisingGoalHeaderContainer {
    justify-content: space-between;
    align-items: center;
    background-color: #5c460a;
    padding: 16px;
    margin-bottom: 15px;
    margin-top: 15px;
    width: 100%;
    border-radius: 10px;
}
.SpecificFundraisingGoalHeader {
    font-size: 18px;
    color: #f3d990;
}
.SpecificFundraisingGoalIcon {
    color: #f3d990;
}

.GeneralFundraisingGoalHeaderContainer {
    justify-content: space-between;
    align-items: center;
    background-color: #2f285e;
    padding: 16px;
    margin-bottom: 15px;
    margin-top: 15px;
    width: 100%;
    border-radius: 10px;
}
.GeneralFundraisingGoalHeader {
    font-size: 18px;
    color: #b5acfb;
}
.GeneralFundraisingGoalIcon {
    color: #b5acfb;
}
