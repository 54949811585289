

/* mobile */
@media screen and (max-width: 899px) {

    .EventContainer {
        width: 331px;
        padding: 15px;
        background-color: #080808;
        border-radius: 16px;
        margin-bottom: 10px;
        /* position: relative;
        left: 50%;
        transform: translate(-50%, 0); */
    }
    .EventSummaryContainer {
        width: 100%;
        align-items: flex-start;
    }
    .EventHeaderContainer {
        width: 100%;
        align-items: center;
        justify-content: space-between;
    }
    .EventHeaderIconContainer {
        align-items: center;
        justify-content: flex-start;
    }
    .EventHeaderIcon {
        background-color: #ffffff;
        border-radius: 20px;
        transform: scale(1.75);
    }
    .EventHeader {
        text-wrap: wrap;
        color: #ffffff;
        font-size: 24px;
        text-align: left;
    }
    .EventSubheader {
        color: #ffffff;
        text-align: left;
    }
    /* .EventSwitch {
        position: relative;
        right: 15%;
    } */

    .EventContributionAmount {
        color: #c1feaf;
    }
    .EventViewDetailsButton {
        color: #c1feaf;
        align-self: flex-end;
        cursor: pointer;
    }

    .EventImageContainer {
        width: 107%;
        min-height: 150px;
        height: auto;
        background-color: #1a1a1a;
        border-radius: 15px;
        position: relative;
    }
    .EventImage {
        width: 100%;
        border-radius: 15px;
    }
    .EventImageIcon {
        color: #a2a3a9;
        position: absolute; 
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) scale(5);
    }

    .EventLinkContainer {
        background-color: #1a1a1a;
        justify-content: space-between;
        align-items: center;
        padding: 15px 0px;
        border-radius: 5px;
        width: 106%;
        position: relative;
    }
    .EventLinkText {
        color: #c1feaf;
        margin-left: 15px;
        text-wrap: nowrap;
    }
    .EventLinkCopyIcon {
        color: #c1feaf;
        margin-right: 15px;
    }
    @keyframes fadeOut {
        0% {
            opacity: 1;
        }
        100% {
            opacity: 0;
        }
    }
    .EventLinkCopyText {
        font-size: 14px;
        color: #a2a3a9;
        background-color: #080808;
        padding: 10px;
        border-radius: 5px;
        position: absolute;
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;
        animation-name: fadeOut;
        animation-duration: 2s;
        animation-delay: 2s;
        animation-fill-mode: forwards;
    }

    

    .EventGoalProgressBarContainer {
        width: 106%;
        padding: 15px 0px;
        background-color: #1a1a1a;
        border-radius: 5px;
        align-items: center;
    }
    .EventGoalProgressBarHeader {
        justify-content: space-between;
        width: 97%;
    }
    .EventGoalProgressBarHeaderRaised {
        color: #83c761;
        font-size: 14px;
    }
    .EventGoalProgressBarHeaderGoal {
        color: #7792e4;
        font-size: 14px;
    }
    .EventGoalProgressBarAmount {
        justify-content: space-between;
        width: 97%;
    }
    .EventGoalProgressBarAmountRaised {
        color: #c1feaf;
    }
    .EventGoalProgressBarAmountGoal {
        color: #c1feaf;
    }
    .EventGoalProgressBarBackground {
        background-color: #080808;
        position: relative;
        width: 97%;
        border-radius: 15px;
        align-self: center;
        margin-top: 10px;
        height: 15px;
    }
    .EventGoalProgressBarBackground .EventGoalProgressBarForeground {
        position: absolute;
        top: 50%;
        left: 0%;
        transform: translate(0, -50%);
        background-color: #c1feaf;
        height: 15px;
        border-radius: 15px 0px 0px 15px;
    }
    .EventGoalProgressBarForegroundBar {
        position: absolute;
        right: 0;
        top: 0;
        height: 20px;
        width: 2px;
        background-color: #83c761;
        transform: translateY(-11%);
    }
    .EventGoalProgressBarBackgroundBar {
        position: absolute;
        top: 0;
        /* right: 0; */
        height: 20px;
        width: 2px;
        background-color: #7792e4;
        transform: translateY(-15%);
        z-index: 1;
    }

    .DynamicEventProgressBarContainer {
        width: 106%;
        background-color: #1a1a1a;
        border-radius: 5px;
        position: relative;
        left: 50%;
        transform: translate(-50%, 0);
        padding: 30px 0px;
    }
    .DynamicEventProgressBarHeader {
        color: #829aff;
        text-align: left;
        font-size: 14px;
        width: 100%;
        position: relative;
        left: -3%
    }
    
    .DynamicEventProgressBarBackground {
        background-color: #080808;
        position: relative;
        width: 88.5%;
        border-radius: 15px 0px 0px 15px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: 15px;
    }
    .DynamicEventProgressBarBackground .DynamicEventProgressBarForeground {
        position: absolute;
        top: 50%;
        left: 0%;
        transform: translate(0, -50%);
        background-color: #c1feaf;
        height: 15px;
        border-radius: 15px 0px 0px 15px;
    }
    .DynamicEventProgressBarForegroundBar {
        position: absolute;
        right: 0;
        top: 0;
        height: 20px;
        width: 2px;
        background-color: #f1f1f1;
        transform: translateY(-11%);
    }
    .DynamicEventProgressBarMinNoMax {
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        align-items: center;
        font-size: 14px;
    }
    .DynamicEventProgressBarMinWithMax {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        align-items: center;
        font-size: 14px;
    }
    .DynamicEventProgressBarMinText {
        color: #f1f1f1;
    }
    .DynamicEventProgressBarMinBar {
        height: 20px;
        width: 2px;
        background-color: #f1f1f1;
    }
    .DynamicEventProgressBarMinAmount {
        color: #f1f1f1;
    }
    .DynamicEventProgressBarMax {
        position: absolute;
        top: 50%;
        right: 1%;
        transform: translate(0, -50%);
        align-items: center;
        font-size: 14px;
    }
    .DynamicEventProgressBarMaxText {
        color: #f1f1f1;
    }
    .DynamicEventProgressBarMaxBar {
        height: 20px;
        width: 2px;
        background-color: #f1f1f1;
    }
    .DynamicEventProgressBarMaxAmount {
        color: #f1f1f1;
    }

    .EventAttendingContainer {
        width: 106%
    }
    .EventAttendingListContainer {
        background-color: #1a1a1a !important;
        border-radius: 5px !important;
    }
    .EventAttendingListItemImage {
        width: 42px;
        height: 42px;
        border-radius: 50%;
    }
    .EventAttendingListItem {
        align-items: center;
        justify-content: space-between;
    }
    .EventAttendingListItemName {
        color: #c1feaf;
    }
    .EventAttendingListItemAddr {
        color: #a2a3a9;
    }
    .EventAttendeesAccordionHeaderList {
        width: 100%;
    }
    .EventAttendingListItemContainer {
        align-items: center;
    }
    .EventAttendingHeader {
        color: #829aff;
        font-size: 14px;
        text-align: left;
    }

    .EventNameContainer {
        width: 106%;
    }
    .EventNameText {
        color: #829aff;
        text-align: left;
        font-size: 14px;
    }
    .EventNameTextField {
        background-color: #080808;
        width: 100%;
        border-radius: 5px;
        text-align: left;
        font-size: 20px;
    }
    
    .EventDescriptionContainer {
        width: 106%;
    }
    .EventDescriptionText {
        color: #829aff;
        text-align: left;
        font-size: 14px;
    }
    .EventDescriptionTextField {
        background-color: #1a1a1a;
        border-radius: 5px;
        text-align: left;
        font-size: 20px;
        
    }

    .EventDetailsContainer {
        width: 106%;
        padding: 15px 0px;
        background-color: #1a1a1a;
        border-radius: 5px;
        align-items: center;
    }
    .EventDetailsSubContainer {
        width: 97%;
    }
    .EventDetailsTextField {
        background-color: #080808;
        border-color: #1a1a1a;
        border-radius: 5px;
        text-align: left;
        font-size: 20px;
    }
    .EventDetailsIcon {
        color: #a2a3a9;
    }
    .EventDetailsText {
        color: #829aff;
        text-align: left;
        font-size: 14px;
    }

    .EventPreferredCurrencyContainer {
        width: 106%;
        border-radius: 5px;
    }
    /* .EventPreferredCurrencyTextField {
        background-color: #080808;
        border-color: white;
        text-align: left;
        font-size: 20px;
        padding: 15px;
    } */

    .EventDeadlineContainer {
        width: 106%;
        border-radius: 5px;
    }
    .EventDeadlineText {
        color: #829aff;
        text-align: left;
        font-size: 14px;
    }

    .EventCheckboxContainer {
        align-items: center;
        align-self: flex-start;
        width: 95%;
    }
    .EventCheckbox {
        color: #a2a3a9 !important;
    }
    .EventCheckboxText {
        color: #a2a3a9;
    }

    .EventCancelEditContainer {
        width: 106%;
        justify-content: space-around;
    }
    .EventCancelButton {
        color: #791521 !important;
        border-color: #791521 !important;
        border-radius: 10px !important;
        text-transform: capitalize !important;
        width: 40% !important;
        font-size: 16px !important;
        font-weight: 600 !important;
        padding: 10px 0px !important;
    }
    .EventEditButton {
        color: #2a4fff !important;
        border-color: #2a4fff !important;
        border-radius: 10px !important;
        text-transform: capitalize !important;
        width: 40% !important;
        font-size: 16px !important;
        font-weight: 600 !important;
        padding: 10px 0px !important;
    }

    .EventTippedContainer {
        background-color: #202020;
        width: 106%;
        padding: 15px 0px;
        border-radius: 10px;
        border-bottom: 1px solid #303030;
    }
    .EventTippedText {
        color: #858585;
        margin-left: 10px;
    }
    .EventTippedDate {
        color: #d6d6d6;
        margin-right: 10px;
    }

    .EventAttendeesContainer {
        border: 1px solid #2d2d2d;
        width: 106%;
        border-radius: 10px;
    }
    .EventAttendeesHeader {
        max-width: 100%;
        padding: 15px;
        border-radius: 10px;
        justify-content: space-between !important;
        background-color: #080808;
    }
    .EventAttendeesHeaderText {
        color: #858585;
    }
    .EventAttendeesHeaderCount {
        color: #c1feaf;
    }
    .EventAttendeesAccordion {
        background-color: #2d2d2d !important;
    }

    .EventAttendeesAccordionHeader {
        width: 100%;
    }
    .EventAttendeesAccordionHeaderText {
        color: #c5c5c5;
    }
    .EventAttendeesAccordionOpenButton {
        color: #c1feaf;
        cursor: pointer;
    }

    .EventAttendeesAccordionDetails {
        align-items: flex-start;
    }
    .EventAttendeesAccordionDetailsName {
        color: #c5c5c5;
    }
    .EventAttendeesAccordionDetailsDownload {
        padding-top: 10px;
        padding-bottom: 10px;
        color: #7792e4;
    }
    .EventAttendeesAccordionDetailsCloseButton {
        color: #c1feaf;
        align-self: flex-end;
        cursor: pointer;
    }

    .EventReturnButton {
        color: #707070 !important;
        border-color: #707070 !important;
        border-radius: 10px !important;
        text-transform: capitalize !important;
        width: 45%;
    }

    .CancelModal {
        width: 331px !important;
        height: 300px !important;
        background-color: #080808 !important;
        position: relative !important;
        left: 50% !important;
        top: 50% !important;
        transform: translate(-50%, -50%) !important;
        align-items: center !important;
        border-radius: 10px !important;
        padding: 0px 10px !important;
        border: 1px solid #a2a3a9 !important;
    }
    .CancelModalHeader {
        font-size: 30px;
        color: #f1f1f1;
        margin-top: 30px;
    }
    .CancelModalText {
        color: #c5c5c5;
        align-self: flex-start !important;
        /* text-transform: uppercase !important; */
        font-size: 18px;
        margin-top: 10px;
    }
    .CancelModalButtonContainer {
        margin-top: 100px;
        width: 100%;
        justify-content: space-evenly;
    }
    .CancelModalNoButton {
        text-transform: capitalize !important;
        width: 100px !important;
        color: #a2a3a9 !important;
        border-color: #a2a3a9 !important;
    }
    .CancelModalYesButton {
        text-transform: capitalize !important;
        width: 100px !important;
        color: #d32f2f !important;
        border-color: #d32f2f !important;
    }

    .EditEvent {
        width: 331px !important;
        height: auto !important;
        background-color: #080808 !important;
        position: relative !important;
        left: 50% !important;
        top: 50% !important;
        transform: translate(-50%, -50%) !important;
        align-items: center !important;
        border-radius: 10px !important;
        padding: 10px !important;
    }
    .EditEventHeader {
        font-size: 30px;
        color: #f1f1f1;
        margin-top: 30px;
        align-self: flex-start;
    }
    .EditEventText {
        color: #829aff;
        align-self: flex-start !important;
        /* text-transform: uppercase !important; */
        font-size: 14px;
        margin-top: 10px;
    }
    .EditEventButtonContainer {
        width: 100%;
        justify-content: space-evenly;
    }
    .EditEventNoButton {
        text-transform: capitalize !important;
        width: 45% !important;
        color: #a2a3a9 !important;
        border-color: #a2a3a9 !important;
        border-radius: 10px !important;
        margin: 0 !important;
        padding: 10px 0px !important;
        font-size: 16px !important;
    }
    .EditEventYesButton {
        text-transform: capitalize !important;
        width: 45% !important;
        color: #f1f1f1 !important;
        background-color: #2a4fff !important;
        border-radius: 10px !important;
        margin: 0 !important;
        padding: 10px 0px !important;
        font-size: 16px !important;
    }
    .EventOptInButton {
        color: #080808 !important;
        background-color: #c1feaf !important;
        border-radius: 10px !important;
        margin: 0 !important;
        width: 250px !important;
        text-transform: capitalize !important;
        font-size: 16px !important;
        margin-top: 20px !important;
    }

}

/* tablet */
@media screen and (min-width: 900px) and (max-width: 1199px) {

    .EventContainer {
        width: 70%;
        padding: 25px;
        background-color: #080808;
        border-radius: 16px;
        margin-bottom: 10px;
        /* position: relative;
        left: 50%;
        transform: translate(-50%, 0); */
    }
    .EventSummaryContainer {
        width: 100%;
        align-items: flex-start;
    }
    .EventHeaderContainer {
        width: 100%;
        align-items: center;
        justify-content: space-between;
    }
    .EventHeaderIconContainer {
        align-items: center;
        justify-content: flex-start;
    }
    .EventHeaderIcon {
        background-color: #ffffff;
        border-radius: 20px;
        transform: scale(1.75);
    }
    .EventHeader {
        text-wrap: wrap;
        color: #ffffff;
        font-size: 24px;
        text-align: left;
    }
    .EventSubheader {
        color: #ffffff;
        text-align: left;
    }
    /* .EventSwitch {
        position: relative;
        right: 15%;
    } */

    .EventContributionAmount {
        color: #c1feaf;
    }
    .EventViewDetailsButton {
        color: #c1feaf;
        align-self: flex-end;
        cursor: pointer;
    }

    .EventImageContainer {
        max-width: 80%;
        min-height: 150px;
        height: auto;
        background-color: #1a1a1a;
        border-radius: 15px;
        position: relative;
    }
    .EventImage {
        width: 100%;
        border-radius: 15px;
    }
    .EventImageIcon {
        color: #a2a3a9;
        position: absolute; 
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) scale(5);
    }

    .EventLinkContainer {
        background-color: #1a1a1a;
        justify-content: space-between;
        align-items: center;
        padding: 15px 0px;
        border-radius: 5px;
        width: 95%;
        position: relative;
    }
    .EventLinkText {
        color: #c1feaf;
        margin-left: 15px;
        text-wrap: nowrap;
    }
    .EventLinkCopyIcon {
        color: #c1feaf;
        margin-right: 15px;
    }
    @keyframes fadeOut {
        0% {
            opacity: 1;
        }
        100% {
            opacity: 0;
        }
    }
    .EventLinkCopyText {
        font-size: 14px;
        color: #a2a3a9;
        background-color: #080808;
        padding: 10px;
        border-radius: 5px;
        position: absolute;
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;
        animation-name: fadeOut;
        animation-duration: 2s;
        animation-delay: 2s;
        animation-fill-mode: forwards;
    }

    

    .EventGoalProgressBarContainer {
        width: 95%;
        padding: 15px 0px;
        background-color: #1a1a1a;
        border-radius: 5px;
        align-items: center;
    }
    .EventGoalProgressBarHeader {
        justify-content: space-between;
        width: 97%;
    }
    .EventGoalProgressBarHeaderRaised {
        color: #83c761;
        font-size: 14px;
    }
    .EventGoalProgressBarHeaderGoal {
        color: #7792e4;
        font-size: 14px;
    }
    .EventGoalProgressBarAmount {
        justify-content: space-between;
        width: 97%;
    }
    .EventGoalProgressBarAmountRaised {
        color: #c1feaf;
    }
    .EventGoalProgressBarAmountGoal {
        color: #c1feaf;
    }
    .EventGoalProgressBarBackground {
        background-color: #080808;
        position: relative;
        width: 97%;
        border-radius: 15px;
        align-self: center;
        margin-top: 10px;
        height: 15px;
    }
    .EventGoalProgressBarBackground .EventGoalProgressBarForeground {
        position: absolute;
        top: 50%;
        left: 0%;
        transform: translate(0, -50%);
        background-color: #c1feaf;
        height: 15px;
        border-radius: 15px 0px 0px 15px;
    }
    .EventGoalProgressBarForegroundBar {
        position: absolute;
        right: 0;
        top: 0;
        height: 20px;
        width: 2px;
        background-color: #83c761;
        transform: translateY(-11%);
    }
    .EventGoalProgressBarBackgroundBar {
        position: absolute;
        top: 0;
        /* right: 0; */
        height: 20px;
        width: 2px;
        background-color: #7792e4;
        transform: translateY(-15%);
        z-index: 1;
    }

    .DynamicEventProgressBarContainer {
        width: 95%;
        background-color: #1a1a1a;
        border-radius: 5px;
        position: relative;
        left: 50%;
        transform: translate(-50%, 0);
        padding: 30px 0px;
    }
    .DynamicEventProgressBarHeader {
        color: #829aff;
        text-align: left;
        font-size: 14px;
        width: 95%;
        position: relative;
        left: 2.5%
    }
    
    .DynamicEventProgressBarBackground {
        background-color: #080808;
        position: relative;
        width: 95%;
        border-radius: 15px 0px 0px 15px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: 15px;
    }
    .DynamicEventProgressBarBackground .DynamicEventProgressBarForeground {
        position: absolute;
        top: 50%;
        left: 0%;
        transform: translate(0, -50%);
        background-color: #c1feaf;
        height: 15px;
        border-radius: 15px 0px 0px 15px;
    }
    .DynamicEventProgressBarForegroundBar {
        position: absolute;
        right: 0;
        top: 0;
        height: 20px;
        width: 2px;
        background-color: #f1f1f1;
        transform: translateY(-11%);
    }
    .DynamicEventProgressBarMinNoMax {
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        align-items: center;
        font-size: 14px;
    }
    .DynamicEventProgressBarMinWithMax {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        align-items: center;
        font-size: 14px;
    }
    .DynamicEventProgressBarMinText {
        color: #f1f1f1;
    }
    .DynamicEventProgressBarMinBar {
        height: 20px;
        width: 2px;
        background-color: #f1f1f1;
    }
    .DynamicEventProgressBarMinAmount {
        color: #f1f1f1;
    }
    .DynamicEventProgressBarMax {
        position: absolute;
        top: 50%;
        right: 1%;
        transform: translate(0, -50%);
        align-items: center;
        font-size: 14px;
    }
    .DynamicEventProgressBarMaxText {
        color: #f1f1f1;
    }
    .DynamicEventProgressBarMaxBar {
        height: 20px;
        width: 2px;
        background-color: #f1f1f1;
    }
    .DynamicEventProgressBarMaxAmount {
        color: #f1f1f1;
    }

    .EventAttendingContainer {
        width: 95%
    }
    .EventAttendingListContainer {
        background-color: #1a1a1a !important;
        border-radius: 5px !important;
    }
    .EventAttendingListItemImage {
        width: 42px;
        height: 42px;
        border-radius: 50%;
    }
    .EventAttendingListItem {
        align-items: center;
        justify-content: space-between;
    }
    .EventAttendingListItemName {
        color: #c1feaf;
    }
    .EventAttendingListItemAddr {
        color: #a2a3a9;
    }
    .EventAttendeesAccordionHeaderList {
        width: 100%;
    }
    .EventAttendingListItemContainer {
        align-items: center;
    }
    .EventAttendingHeader {
        color: #829aff;
        font-size: 14px;
        text-align: left;
    }

    .EventNameContainer {
        width: 95%;
    }
    .EventNameText {
        color: #829aff;
        text-align: left;
        font-size: 14px;
    }
    .EventNameTextField {
        background-color: #080808;
        width: 100%;
        border-radius: 5px;
        text-align: left;
        font-size: 20px;
    }
    
    .EventDescriptionContainer {
        width: 95%;
    }
    .EventDescriptionText {
        color: #829aff;
        text-align: left;
        font-size: 14px;
    }
    .EventDescriptionTextField {
        background-color: #1a1a1a;
        border-radius: 5px;
        text-align: left;
        font-size: 20px;
        
    }

    .EventDetailsContainer {
        width: 95%;
        padding: 15px 0px;
        background-color: #1a1a1a;
        border-radius: 5px;
        align-items: center;
    }
    .EventDetailsSubContainer {
        width: 97%;
    }
    .EventDetailsTextField {
        background-color: #080808;
        border-color: #1a1a1a;
        border-radius: 5px;
        text-align: left;
        font-size: 20px;
    }
    .EventDetailsIcon {
        color: #a2a3a9;
    }
    .EventDetailsText {
        color: #829aff;
        text-align: left;
        font-size: 14px;
    }

    .EventPreferredCurrencyContainer {
        width: 95%;
        border-radius: 5px;
    }
    /* .EventPreferredCurrencyTextField {
        background-color: #080808;
        border-color: white;
        text-align: left;
        font-size: 20px;
        padding: 15px;
    } */

    .EventDeadlineContainer {
        width: 95%;
        border-radius: 5px;
    }
    .EventDeadlineText {
        color: #829aff;
        text-align: left;
        font-size: 14px;
    }

    .EventCheckboxContainer {
        align-items: center;
        align-self: flex-start;
        width: 95%;
    }
    .EventCheckbox {
        color: #a2a3a9 !important;
    }
    .EventCheckboxText {
        color: #a2a3a9;
    }

    .EventCancelEditContainer {
        width: 95%;
        justify-content: space-around;
    }
    .EventCancelButton {
        color: #791521 !important;
        border-color: #791521 !important;
        border-radius: 10px !important;
        text-transform: capitalize !important;
        width: 40% !important;
        font-size: 20px !important;
        font-weight: 600 !important;
        padding: 10px 0px !important;
    }
    .EventEditButton {
        color: #2a4fff !important;
        border-color: #2a4fff !important;
        border-radius: 10px !important;
        text-transform: capitalize !important;
        width: 40% !important;
        font-size: 20px !important;
        font-weight: 600 !important;
        padding: 10px 0px !important;
    }

    .EventTippedContainer {
        background-color: #202020;
        width: 95%;
        padding: 15px 0px;
        border-radius: 10px;
        border-bottom: 1px solid #303030;
    }
    .EventTippedText {
        color: #858585;
        margin-left: 10px;
    }
    .EventTippedDate {
        color: #d6d6d6;
        margin-right: 10px;
    }

    .EventAttendeesContainer {
        border: 1px solid #2d2d2d;
        width: 95%;
        border-radius: 10px;
    }
    .EventAttendeesHeader {
        max-width: 100%;
        padding: 15px;
        border-radius: 10px;
        justify-content: space-between !important;
        background-color: #080808;
    }
    .EventAttendeesHeaderText {
        color: #858585;
    }
    .EventAttendeesHeaderCount {
        color: #c1feaf;
    }
    .EventAttendeesAccordion {
        background-color: #2d2d2d !important;
    }

    .EventAttendeesAccordionHeader {
        width: 100%;
    }
    .EventAttendeesAccordionHeaderText {
        color: #c5c5c5;
    }
    .EventAttendeesAccordionOpenButton {
        color: #c1feaf;
        cursor: pointer;
    }

    .EventAttendeesAccordionDetails {
        align-items: flex-start;
    }
    .EventAttendeesAccordionDetailsName {
        color: #c5c5c5;
    }
    .EventAttendeesAccordionDetailsDownload {
        padding-top: 10px;
        padding-bottom: 10px;
        color: #7792e4;
    }
    .EventAttendeesAccordionDetailsCloseButton {
        color: #c1feaf;
        align-self: flex-end;
        cursor: pointer;
    }

    .EventReturnButton {
        color: #707070 !important;
        border-color: #707070 !important;
        border-radius: 10px !important;
        text-transform: capitalize !important;
        width: 45%;
    }

    .CancelModal {
        width: 331px !important;
        height: 300px !important;
        background-color: #080808 !important;
        position: relative !important;
        left: 50% !important;
        top: 50% !important;
        transform: translate(-50%, -50%) !important;
        align-items: center !important;
        border-radius: 10px !important;
        padding: 0px 10px !important;
        border: 1px solid #a2a3a9 !important;
    }
    .CancelModalHeader {
        font-size: 30px;
        color: #f1f1f1;
        margin-top: 30px;
    }
    .CancelModalText {
        color: #c5c5c5;
        align-self: flex-start !important;
        /* text-transform: uppercase !important; */
        font-size: 18px;
        margin-top: 10px;
    }
    .CancelModalButtonContainer {
        margin-top: 100px;
        width: 100%;
        justify-content: space-evenly;
    }
    .CancelModalNoButton {
        text-transform: capitalize !important;
        width: 100px !important;
        color: #a2a3a9 !important;
        border-color: #a2a3a9 !important;
    }
    .CancelModalYesButton {
        text-transform: capitalize !important;
        width: 100px !important;
        color: #d32f2f !important;
        border-color: #d32f2f !important;
    }

    .EditEvent {
        width: 331px !important;
        height: auto !important;
        background-color: #080808 !important;
        position: relative !important;
        left: 50% !important;
        top: 50% !important;
        transform: translate(-50%, -50%) !important;
        align-items: center !important;
        border-radius: 10px !important;
        padding: 10px !important;
    }
    .EditEventHeader {
        font-size: 30px;
        color: #f1f1f1;
        margin-top: 30px;
        align-self: flex-start;
    }
    .EditEventText {
        color: #829aff;
        align-self: flex-start !important;
        /* text-transform: uppercase !important; */
        font-size: 14px;
        margin-top: 10px;
    }
    .EditEventButtonContainer {
        width: 100%;
        justify-content: space-evenly;
    }
    .EditEventNoButton {
        text-transform: capitalize !important;
        width: 45% !important;
        color: #a2a3a9 !important;
        border-color: #a2a3a9 !important;
        border-radius: 10px !important;
        margin: 0 !important;
        padding: 10px 0px !important;
        font-size: 16px !important;
    }
    .EditEventYesButton {
        text-transform: capitalize !important;
        width: 45% !important;
        color: #f1f1f1 !important;
        background-color: #2a4fff !important;
        border-radius: 10px !important;
        margin: 0 !important;
        padding: 10px 0px !important;
        font-size: 16px !important;
    }
    .EventOptInButton {
        color: #080808 !important;
        background-color: #c1feaf !important;
        border-radius: 10px !important;
        margin: 0 !important;
        width: 250px !important;
        text-transform: capitalize !important;
        font-size: 16px !important;
        margin-top: 20px !important;
    }

}

/* desktop */
@media screen and (min-width: 1200px) {

    .EventContainer {
        width: 70%;
        padding: 25px;
        background-color: #080808;
        border-radius: 16px;
        margin-bottom: 10px;
        /* position: relative;
        left: 50%;
        transform: translate(-50%, 0); */
    }
    .EventSummaryContainer {
        width: 100%;
        align-items: flex-start;
    }
    .EventHeaderContainer {
        width: 100%;
        align-items: center;
        justify-content: space-between;
    }
    .EventHeaderIconContainer {
        align-items: center;
        justify-content: flex-start;
    }
    .EventHeaderIcon {
        background-color: #ffffff;
        border-radius: 20px;
        transform: scale(1.75);
    }
    .EventHeader {
        text-wrap: wrap;
        color: #ffffff;
        font-size: 24px;
        text-align: left;
    }
    .EventSubheader {
        color: #ffffff;
        text-align: left;
    }
    /* .EventSwitch {
        position: relative;
        right: 15%;
    } */

    .EventContributionAmount {
        color: #c1feaf;
    }
    .EventViewDetailsButton {
        color: #c1feaf;
        align-self: flex-end;
        cursor: pointer;
    }

    .EventImageContainer {
        max-width: 80%;
        min-height: 150px;
        height: auto;
        background-color: #1a1a1a;
        border-radius: 15px;
        position: relative;
    }
    .EventImage {
        width: 100%;
        border-radius: 15px;
    }
    .EventImageIcon {
        color: #a2a3a9;
        position: absolute; 
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) scale(5);
    }

    .EventLinkContainer {
        background-color: #1a1a1a;
        justify-content: space-between;
        align-items: center;
        padding: 15px 0px;
        border-radius: 5px;
        width: 95%;
        position: relative;
    }
    .EventLinkText {
        color: #c1feaf;
        margin-left: 15px;
        text-wrap: nowrap;
    }
    .EventLinkCopyIcon {
        color: #c1feaf;
        margin-right: 15px;
    }
    @keyframes fadeOut {
        0% {
            opacity: 1;
        }
        100% {
            opacity: 0;
        }
    }
    .EventLinkCopyText {
        font-size: 14px;
        color: #a2a3a9;
        background-color: #080808;
        padding: 10px;
        border-radius: 5px;
        position: absolute;
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;
        animation-name: fadeOut;
        animation-duration: 2s;
        animation-delay: 2s;
        animation-fill-mode: forwards;
    }

    

    .EventGoalProgressBarContainer {
        width: 95%;
        padding: 15px 0px;
        background-color: #1a1a1a;
        border-radius: 5px;
        align-items: center;
    }
    .EventGoalProgressBarHeader {
        justify-content: space-between;
        width: 97%;
    }
    .EventGoalProgressBarHeaderRaised {
        color: #83c761;
        font-size: 14px;
    }
    .EventGoalProgressBarHeaderGoal {
        color: #7792e4;
        font-size: 14px;
    }
    .EventGoalProgressBarAmount {
        justify-content: space-between;
        width: 97%;
    }
    .EventGoalProgressBarAmountRaised {
        color: #c1feaf;
    }
    .EventGoalProgressBarAmountGoal {
        color: #c1feaf;
    }
    .EventGoalProgressBarBackground {
        background-color: #080808;
        position: relative;
        width: 97%;
        border-radius: 15px;
        align-self: center;
        margin-top: 10px;
        height: 15px;
    }
    .EventGoalProgressBarBackground .EventGoalProgressBarForeground {
        position: absolute;
        top: 50%;
        left: 0%;
        transform: translate(0, -50%);
        background-color: #c1feaf;
        height: 15px;
        border-radius: 15px 0px 0px 15px;
    }
    .EventGoalProgressBarForegroundBar {
        position: absolute;
        right: 0;
        top: 0;
        height: 20px;
        width: 2px;
        background-color: #83c761;
        transform: translateY(-11%);
    }
    .EventGoalProgressBarBackgroundBar {
        position: absolute;
        top: 0;
        /* right: 0; */
        height: 20px;
        width: 2px;
        background-color: #7792e4;
        transform: translateY(-15%);
        z-index: 1;
    }

    .DynamicEventProgressBarContainer {
        width: 95%;
        background-color: #1a1a1a;
        border-radius: 5px;
        position: relative;
        left: 50%;
        transform: translate(-50%, 0);
        padding: 30px 0px;
    }
    .DynamicEventProgressBarHeader {
        color: #829aff;
        text-align: left;
        font-size: 14px;
        width: 95%;
        position: relative;
        left: 2.5%
    }
    
    .DynamicEventProgressBarBackground {
        background-color: #080808;
        position: relative;
        width: 95%;
        border-radius: 15px 0px 0px 15px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: 15px;
    }
    .DynamicEventProgressBarBackground .DynamicEventProgressBarForeground {
        position: absolute;
        top: 50%;
        left: 0%;
        transform: translate(0, -50%);
        background-color: #c1feaf;
        height: 15px;
        border-radius: 15px 0px 0px 15px;
    }
    .DynamicEventProgressBarForegroundBar {
        position: absolute;
        right: 0;
        top: 0;
        height: 20px;
        width: 2px;
        background-color: #f1f1f1;
        transform: translateY(-11%);
    }
    .DynamicEventProgressBarMinNoMax {
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        align-items: center;
        font-size: 14px;
    }
    .DynamicEventProgressBarMinWithMax {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        align-items: center;
        font-size: 14px;
    }
    .DynamicEventProgressBarMinText {
        color: #f1f1f1;
    }
    .DynamicEventProgressBarMinBar {
        height: 20px;
        width: 2px;
        background-color: #f1f1f1;
    }
    .DynamicEventProgressBarMinAmount {
        color: #f1f1f1;
    }
    .DynamicEventProgressBarMax {
        position: absolute;
        top: 50%;
        right: 1%;
        transform: translate(0, -50%);
        align-items: center;
        font-size: 14px;
    }
    .DynamicEventProgressBarMaxText {
        color: #f1f1f1;
    }
    .DynamicEventProgressBarMaxBar {
        height: 20px;
        width: 2px;
        background-color: #f1f1f1;
    }
    .DynamicEventProgressBarMaxAmount {
        color: #f1f1f1;
    }

    .EventAttendingContainer {
        width: 95%
    }
    .EventAttendingListContainer {
        background-color: #1a1a1a !important;
        border-radius: 5px !important;
    }
    .EventAttendingListItemImage {
        width: 42px;
        height: 42px;
        border-radius: 50%;
    }
    .EventAttendingListItem {
        align-items: center;
        justify-content: space-between;
    }
    .EventAttendingListItemName {
        color: #c1feaf;
    }
    .EventAttendingListItemAddr {
        color: #a2a3a9;
    }
    .EventAttendeesAccordionHeaderList {
        width: 100%;
    }
    .EventAttendingListItemContainer {
        align-items: center;
    }
    .EventAttendingHeader {
        color: #829aff;
        font-size: 14px;
        text-align: left;
    }

    .EventNameContainer {
        width: 95%;
    }
    .EventNameText {
        color: #829aff;
        text-align: left;
        font-size: 14px;
    }
    .EventNameTextField {
        background-color: #080808;
        width: 100%;
        border-radius: 5px;
        text-align: left;
        font-size: 20px;
    }
    
    .EventDescriptionContainer {
        width: 95%;
    }
    .EventDescriptionText {
        color: #829aff;
        text-align: left;
        font-size: 14px;
    }
    .EventDescriptionTextField {
        background-color: #1a1a1a;
        border-radius: 5px;
        text-align: left;
        font-size: 20px;
        
    }

    .EventDetailsContainer {
        width: 95%;
        padding: 15px 0px;
        background-color: #1a1a1a;
        border-radius: 5px;
        align-items: center;
    }
    .EventDetailsSubContainer {
        width: 97%;
    }
    .EventDetailsTextField {
        background-color: #080808;
        border-color: #1a1a1a;
        border-radius: 5px;
        text-align: left;
        font-size: 20px;
    }
    .EventDetailsIcon {
        color: #a2a3a9;
    }
    .EventDetailsText {
        color: #829aff;
        text-align: left;
        font-size: 14px;
    }

    .EventPreferredCurrencyContainer {
        width: 95%;
        border-radius: 5px;
    }
    /* .EventPreferredCurrencyTextField {
        background-color: #080808;
        border-color: white;
        text-align: left;
        font-size: 20px;
        padding: 15px;
    } */

    .EventDeadlineContainer {
        width: 95%;
        border-radius: 5px;
    }
    .EventDeadlineText {
        color: #829aff;
        text-align: left;
        font-size: 14px;
    }

    .EventCheckboxContainer {
        align-items: center;
        align-self: flex-start;
        width: 95%;
    }
    .EventCheckbox {
        color: #a2a3a9 !important;
    }
    .EventCheckboxText {
        color: #a2a3a9;
    }

    .EventCancelEditContainer {
        width: 95%;
        justify-content: space-around;
    }
    .EventCancelButton {
        color: #791521 !important;
        border-color: #791521 !important;
        border-radius: 10px !important;
        text-transform: capitalize !important;
        width: 40% !important;
        font-size: 20px !important;
        font-weight: 600 !important;
        padding: 10px 0px !important;
    }
    .EventEditButton {
        color: #2a4fff !important;
        border-color: #2a4fff !important;
        border-radius: 10px !important;
        text-transform: capitalize !important;
        width: 40% !important;
        font-size: 20px !important;
        font-weight: 600 !important;
        padding: 10px 0px !important;
    }

    .EventTippedContainer {
        background-color: #202020;
        width: 95%;
        padding: 15px 0px;
        border-radius: 10px;
        border-bottom: 1px solid #303030;
    }
    .EventTippedText {
        color: #858585;
        margin-left: 10px;
    }
    .EventTippedDate {
        color: #d6d6d6;
        margin-right: 10px;
    }

    .EventAttendeesContainer {
        border: 1px solid #2d2d2d;
        width: 95%;
        border-radius: 10px;
    }
    .EventAttendeesHeader {
        max-width: 100%;
        padding: 15px;
        border-radius: 10px;
        justify-content: space-between !important;
        background-color: #080808;
    }
    .EventAttendeesHeaderText {
        color: #858585;
    }
    .EventAttendeesHeaderCount {
        color: #c1feaf;
    }
    .EventAttendeesAccordion {
        background-color: #2d2d2d !important;
    }

    .EventAttendeesAccordionHeader {
        width: 100%;
    }
    .EventAttendeesAccordionHeaderText {
        color: #c5c5c5;
    }
    .EventAttendeesAccordionOpenButton {
        color: #c1feaf;
        cursor: pointer;
    }

    .EventAttendeesAccordionDetails {
        align-items: flex-start;
    }
    .EventAttendeesAccordionDetailsName {
        color: #c5c5c5;
    }
    .EventAttendeesAccordionDetailsDownload {
        padding-top: 10px;
        padding-bottom: 10px;
        color: #7792e4;
    }
    .EventAttendeesAccordionDetailsCloseButton {
        color: #c1feaf;
        align-self: flex-end;
        cursor: pointer;
    }

    .EventReturnButton {
        color: #707070 !important;
        border-color: #707070 !important;
        border-radius: 10px !important;
        text-transform: capitalize !important;
        width: 45%;
    }

    .CancelModal {
        width: 331px !important;
        height: 300px !important;
        background-color: #080808 !important;
        position: relative !important;
        left: 50% !important;
        top: 50% !important;
        transform: translate(-50%, -50%) !important;
        align-items: center !important;
        border-radius: 10px !important;
        padding: 0px 10px !important;
        border: 1px solid #a2a3a9 !important;
    }
    .CancelModalHeader {
        font-size: 30px;
        color: #f1f1f1;
        margin-top: 30px;
    }
    .CancelModalText {
        color: #c5c5c5;
        align-self: flex-start !important;
        /* text-transform: uppercase !important; */
        font-size: 18px;
        margin-top: 10px;
    }
    .CancelModalButtonContainer {
        margin-top: 100px;
        width: 100%;
        justify-content: space-evenly;
    }
    .CancelModalNoButton {
        text-transform: capitalize !important;
        width: 100px !important;
        color: #a2a3a9 !important;
        border-color: #a2a3a9 !important;
    }
    .CancelModalYesButton {
        text-transform: capitalize !important;
        width: 100px !important;
        color: #d32f2f !important;
        border-color: #d32f2f !important;
    }

    .EditEvent {
        width: 331px !important;
        height: auto !important;
        background-color: #080808 !important;
        position: relative !important;
        left: 50% !important;
        top: 50% !important;
        transform: translate(-50%, -50%) !important;
        align-items: center !important;
        border-radius: 10px !important;
        padding: 10px !important;
    }
    .EditEventHeader {
        font-size: 30px;
        color: #f1f1f1;
        margin-top: 30px;
        align-self: flex-start;
    }
    .EditEventText {
        color: #829aff;
        align-self: flex-start !important;
        /* text-transform: uppercase !important; */
        font-size: 14px;
        margin-top: 10px;
    }
    .EditEventButtonContainer {
        width: 100%;
        justify-content: space-evenly;
    }
    .EditEventNoButton {
        text-transform: capitalize !important;
        width: 45% !important;
        color: #a2a3a9 !important;
        border-color: #a2a3a9 !important;
        border-radius: 10px !important;
        margin: 0 !important;
        padding: 10px 0px !important;
        font-size: 16px !important;
    }
    .EditEventYesButton {
        text-transform: capitalize !important;
        width: 45% !important;
        color: #f1f1f1 !important;
        background-color: #2a4fff !important;
        border-radius: 10px !important;
        margin: 0 !important;
        padding: 10px 0px !important;
        font-size: 16px !important;
    }
    .EventOptInButton {
        color: #080808 !important;
        background-color: #c1feaf !important;
        border-radius: 10px !important;
        margin: 0 !important;
        width: 250px !important;
        text-transform: capitalize !important;
        font-size: 16px !important;
        margin-top: 20px !important;
    }

}