

.ContactUsHeader {
    font-size: 40px;
    font-weight: 600;
    text-align: center;
    color: #c1feaf;
}

.ContactUsSubHeader {
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    color: #829aff;
}

.TextFieldContainer {
    align-items: center;
    width: 100%;
}

.ContactUsTextField {
    width: 90%;
}


