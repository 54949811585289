

/* Mobile */
/* @media screen and (max-width: 899px) { */
  .TableCellIcon {
    color: #c5c6d0;
    transform: none;
  }
  .TableCellIconFlipped {
    color: #c5c6d0;
    transform: rotate(180deg);
  }

  .EventHistoryHeader {
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    color: #c1feaf;
    text-align: left;
    padding-left: 20px;
  }
  .EventHistorySearchBar {
    width: 90%;
    align-self: center;
  }
  .EventHistorySearchInput {
    color: #cac4d0;
  }
  .EventHistorySearchIcon {
    color: #cac4d0 !important;
    
  }
  .EventHistorySearchIcon::placeholder {
    color: #cac4d0 !important;
    
  }

  .EventHistoryTableHeader {
    background-color: #1a1a1a;
    justify-content: space-between;
    border-bottom: 1px solid #303030;
  }
  .EventHistoryHeaderCell {
    padding: 20px 10px;
  }
  .EventHistoryTableColumnText {
    color: #858585;
    font-size: 14px;
  }
  .EventHistoryTable {
    width: 100%;
  }

  .EventHistoryRowEven {
    background-color: #2b2b2b;
    padding: 20px 0;
    align-items: center;
    position: relative;
    cursor: pointer;
  }
  .EventHistoryRowOdd {
    background-color: #2e2e2e;
    padding: 20px 0;
    align-items: center;
    position: relative;
    cursor: pointer;
  }

  .EventHistoryRowName {
    position: relative;
    left: 1%;
    /* width: 35%; */
    text-align: left;
    color: #c2c2c2;
    font-size: 14px;
  }
  .EventHistoryRowCreator {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    color: #c2c2c2;
    font-size: 14px;
  }
  .EventHistoryRowAmount {
    position: absolute;
    right: 0;
    transform: translateX(-50%);
    color: #c2c2c2;
    font-size: 14px;
  }

  .EventHistoryDownloadContainer {
    margin-top: 20px !important;
    margin-bottom: 30px !important;
    align-self: center !important;
    position: relative;
  }
  .EventHistoryDownloadIcon {
    color: #7792e4;
  } 
  .EventHistoryDownloadText {
    color: #7792e4;
    font-size: 16px;
  }

  .EventHistoryActiveButton {
    text-transform: capitalize !important;
    background-color: #2a4fff !important;
    border-radius: 10px !important;
    color: #f1f1f1 !important;
    width: 300px !important;
    align-self: center !important;
    font-size: 16px !important;
    padding: 10px !important;
    
  }
  .EventHistoryCreateButton {
    text-transform: capitalize !important;
    border-radius: 10px !important;
    color: #2a4fff !important;
    width: 300px !important;
    align-self: center !important;
    font-size: 16px !important;
    padding: 10px !important;
  }


/* } */