

.EventLinkText {
    color: #c1feaf;
    margin-left: 15px;
    text-wrap: nowrap;
}
.EventLinkCopyIcon {
    color: #c1feaf;
    margin-right: 15px;
}
@keyframes fadeOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
.EventLinkCopyText {
    font-size: 14px;
    color: #a2a3a9;
    background-color: #080808;
    padding: 10px;
    border-radius: 5px;
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    animation-name: fadeOut;
    animation-duration: 2s;
    animation-delay: 2s;
    animation-fill-mode: forwards;
}