

body { font-family:Arial; font-size:12pt }
			h1, h2, h3, h4, h5, h6, p { margin:0pt }
			li { margin-top:0pt; margin-bottom:0pt }
			h1 { margin-top:18pt; margin-bottom:4pt; page-break-inside:avoid; page-break-after:avoid; font-family:'Aptos Display'; font-size:20pt; font-weight:normal; color:#0f4761 }
			h2 { margin-top:8pt; margin-bottom:4pt; page-break-inside:avoid; page-break-after:avoid; font-family:'Aptos Display'; font-size:16pt; font-weight:normal; color:#0f4761 }
			h3 { margin-top:8pt; margin-bottom:4pt; page-break-inside:avoid; page-break-after:avoid; font-family:Arial; font-size:14pt; font-weight:normal; color:#0f4761 }
			h4 { margin-top:4pt; margin-bottom:2pt; page-break-inside:avoid; page-break-after:avoid; font-family:Arial; font-size:12pt; font-weight:normal; font-style:italic; color:#0f4761 }
			h5 { margin-top:4pt; margin-bottom:2pt; page-break-inside:avoid; page-break-after:avoid; font-family:Arial; font-size:12pt; font-weight:normal; color:#0f4761 }
			h6 { margin-top:2pt; page-break-inside:avoid; page-break-after:avoid; font-family:Arial; font-size:12pt; font-weight:normal; font-style:italic; color:#595959 }
			.Heading7 { margin-top:2pt; page-break-inside:avoid; page-break-after:avoid; font-family:Arial; font-size:12pt; font-weight:normal; font-style:normal; color:#595959 }
			.Heading8 { page-break-inside:avoid; page-break-after:avoid; font-family:Arial; font-size:12pt; font-weight:normal; font-style:italic; color:#272727 }
			.Heading9 { page-break-inside:avoid; page-break-after:avoid; font-family:Arial; font-size:12pt; font-weight:normal; font-style:normal; color:#272727 }
			.BodyText { margin-bottom:6pt; font-size:12pt }
			.FootnoteText { font-size:10pt }
			.IntenseQuote { margin:18pt 43.2pt; text-align:center; border-top:0.75pt solid #0f4761; border-bottom:0.75pt solid #0f4761; padding-top:10pt; padding-bottom:10pt; font-size:12pt; font-style:italic; color:#0f4761 }
			.ListParagraph { margin-left:36pt; font-size:12pt }
			.MFPara-Clause { margin-left:0pt; text-indent:21.6pt; font-size:12pt }
			.MFParasubclause1 { margin-left:21.6pt; text-indent:28.8pt; font-size:12pt }
			.MFParasubclause2 { margin-left:50.4pt; text-indent:36pt; font-size:12pt }
			.MFParasubclause3 { margin-left:86.4pt; text-indent:36pt; font-size:12pt }
			.MFParasubclause4 { margin-left:122.4pt; text-indent:36pt; font-size:12pt }
			.NormalWeb { margin-top:5pt; margin-bottom:5pt; font-family:'Times New Roman'; font-size:12pt }
			.Quote { margin-top:8pt; margin-bottom:8pt; text-align:center; font-size:12pt; font-style:italic; color:#404040 }
			.Subtitle { margin-bottom:8pt; font-size:14pt; letter-spacing:0.75pt; color:#595959 }
			.Title { margin-bottom:4pt; font-family:'Aptos Display'; font-size:28pt; letter-spacing:-0.5pt }
			span.FootnoteReference { vertical-align:super }
			span.FootnoteTextChar { font-size:10pt }
			span.Heading1Char { font-family:'Aptos Display'; font-size:20pt; color:#0f4761 }
			span.Heading2Char { font-family:'Aptos Display'; font-size:16pt; color:#0f4761 }
			span.Heading3Char { font-size:14pt; color:#0f4761 }
			span.Heading4Char { font-style:italic; color:#0f4761 }
			span.Heading5Char { color:#0f4761 }
			span.Heading6Char { font-style:italic; color:#595959 }
			span.Heading7Char { color:#595959 }
			span.Heading8Char { font-style:italic; color:#272727 }
			span.Heading9Char { color:#272727 }
			span.Hyperlink { text-decoration:underline; color:#0000ff }
			span.IntenseEmphasis { font-style:italic; color:#0f4761 }
			span.IntenseQuoteChar { font-style:italic; color:#0f4761 }
			span.IntenseReference { font-weight:bold; font-variant:small-caps; letter-spacing:0.25pt; color:#0f4761 }
			span.QuoteChar { font-style:italic; color:#404040 }
			span.Strong { font-weight:bold }
			span.SubtitleChar { font-size:14pt; letter-spacing:0.75pt; color:#595959 }
			span.TitleChar { font-family:'Aptos Display'; font-size:28pt; letter-spacing:-0.5pt }
			span.UnresolvedMention { color:#605e5c; background-color:#e1dfdd }
			.awlist1 { list-style:none; counter-reset:awlistcounter3_0 }
			.awlist1 > li:before { content:counter(awlistcounter3_0) '.'; counter-increment:awlistcounter3_0 }
			.awlist2 { list-style:none; counter-reset:awlistcounter3_1 }
			.awlist2 > li:before { content:'1.' counter(awlistcounter3_1); counter-increment:awlistcounter3_1 }
			.awlist3 { list-style:none; counter-reset:awlistcounter3_1 1 }
			.awlist3 > li:before { content:'1.' counter(awlistcounter3_1); counter-increment:awlistcounter3_1 }
			.awlist4 { list-style:none; counter-reset:awlistcounter3_1 2 }
			.awlist4 > li:before { content:'1.' counter(awlistcounter3_1); counter-increment:awlistcounter3_1 }
			.awlist5 { list-style:none; counter-reset:awlistcounter3_1 3 }
			.awlist5 > li:before { content:'1.' counter(awlistcounter3_1); counter-increment:awlistcounter3_1 }
			.awlist6 { list-style:none; counter-reset:awlistcounter3_1 4 }
			.awlist6 > li:before { content:'1.' counter(awlistcounter3_1); counter-increment:awlistcounter3_1 }
			.awlist7 { list-style:none; counter-reset:awlistcounter3_0 1 }
			.awlist7 > li:before { content:counter(awlistcounter3_0) '.'; counter-increment:awlistcounter3_0 }
			.awlist8 { list-style:none; counter-reset:awlistcounter3_1 }
			.awlist8 > li:before { content:'2.' counter(awlistcounter3_1); counter-increment:awlistcounter3_1 }
			.awlist9 { list-style:none; counter-reset:awlistcounter3_1 1 }
			.awlist9 > li:before { content:'2.' counter(awlistcounter3_1); counter-increment:awlistcounter3_1 }
			.awlist10 { list-style:none; counter-reset:awlistcounter3_0 2 }
			.awlist10 > li:before { content:counter(awlistcounter3_0) '.'; counter-increment:awlistcounter3_0 }
			.awlist11 { list-style:none; counter-reset:awlistcounter3_1 }
			.awlist11 > li:before { content:'3.' counter(awlistcounter3_1); counter-increment:awlistcounter3_1 }
			.awlist12 { list-style:none; counter-reset:awlistcounter3_1 1 }
			.awlist12 > li:before { content:'3.' counter(awlistcounter3_1); counter-increment:awlistcounter3_1 }
			.awlist13 { list-style:none; counter-reset:awlistcounter3_1 2 }
			.awlist13 > li:before { content:'3.' counter(awlistcounter3_1); counter-increment:awlistcounter3_1 }
			.awlist14 { list-style:none; counter-reset:awlistcounter3_1 3 }
			.awlist14 > li:before { content:'3.' counter(awlistcounter3_1); counter-increment:awlistcounter3_1 }
			.awlist15 { list-style:none; counter-reset:awlistcounter3_1 4 }
			.awlist15 > li:before { content:'3.' counter(awlistcounter3_1); counter-increment:awlistcounter3_1 }
			.awlist16 { list-style:none; counter-reset:awlistcounter3_0 3 }
			.awlist16 > li:before { content:counter(awlistcounter3_0) '.'; counter-increment:awlistcounter3_0 }
			.awlist17 { list-style:none; counter-reset:awlistcounter3_1 }
			.awlist17 > li:before { content:'4.' counter(awlistcounter3_1); counter-increment:awlistcounter3_1 }
			.awlist18 { list-style:none; counter-reset:awlistcounter3_1 1 }
			.awlist18 > li:before { content:'4.' counter(awlistcounter3_1); counter-increment:awlistcounter3_1 }
			.awlist19 { list-style:none; counter-reset:awlistcounter3_0 4 }
			.awlist19 > li:before { content:counter(awlistcounter3_0) '.'; counter-increment:awlistcounter3_0 }
			.awlist20 { list-style:none; counter-reset:awlistcounter3_1 }
			.awlist20 > li:before { content:'5.' counter(awlistcounter3_1); counter-increment:awlistcounter3_1 }
			.awlist21 { list-style:none; counter-reset:awlistcounter3_2 }
			.awlist21 > li:before { content:'(' counter(awlistcounter3_2, lower-latin) ')'; counter-increment:awlistcounter3_2 }
			.awlist22 { list-style:none; counter-reset:awlistcounter3_2 1 }
			.awlist22 > li:before { content:'(' counter(awlistcounter3_2, lower-latin) ')'; counter-increment:awlistcounter3_2 }
			.awlist23 { list-style:none; counter-reset:awlistcounter3_2 2 }
			.awlist23 > li:before { content:'(' counter(awlistcounter3_2, lower-latin) ')'; counter-increment:awlistcounter3_2 }
			.awlist24 { list-style:none; counter-reset:awlistcounter3_2 3 }
			.awlist24 > li:before { content:'(' counter(awlistcounter3_2, lower-latin) ')'; counter-increment:awlistcounter3_2 }
			.awlist25 { list-style:none; counter-reset:awlistcounter3_2 4 }
			.awlist25 > li:before { content:'(' counter(awlistcounter3_2, lower-latin) ')'; counter-increment:awlistcounter3_2 }
			.awlist26 { list-style:none; counter-reset:awlistcounter3_2 5 }
			.awlist26 > li:before { content:'(' counter(awlistcounter3_2, lower-latin) ')'; counter-increment:awlistcounter3_2 }
			.awlist27 { list-style:none; counter-reset:awlistcounter3_2 6 }
			.awlist27 > li:before { content:'(' counter(awlistcounter3_2, lower-latin) ')'; counter-increment:awlistcounter3_2 }
			.awlist28 { list-style:none; counter-reset:awlistcounter3_2 7 }
			.awlist28 > li:before { content:'(' counter(awlistcounter3_2, lower-latin) ')'; counter-increment:awlistcounter3_2 }
			.awlist29 { list-style:none; counter-reset:awlistcounter3_2 8 }
			.awlist29 > li:before { content:'(' counter(awlistcounter3_2, lower-latin) ')'; counter-increment:awlistcounter3_2 }
			.awlist30 { list-style:none; counter-reset:awlistcounter3_2 9 }
			.awlist30 > li:before { content:'(' counter(awlistcounter3_2, lower-latin) ')'; counter-increment:awlistcounter3_2 }
			.awlist31 { list-style:none; counter-reset:awlistcounter3_2 10 }
			.awlist31 > li:before { content:'(' counter(awlistcounter3_2, lower-latin) ')'; counter-increment:awlistcounter3_2 }
			.awlist32 { list-style:none; counter-reset:awlistcounter3_2 11 }
			.awlist32 > li:before { content:'(' counter(awlistcounter3_2, lower-latin) ')'; counter-increment:awlistcounter3_2 }
			.awlist33 { list-style:none; counter-reset:awlistcounter3_2 12 }
			.awlist33 > li:before { content:'(' counter(awlistcounter3_2, lower-latin) ')'; counter-increment:awlistcounter3_2 }
			.awlist34 { list-style:none; counter-reset:awlistcounter3_2 13 }
			.awlist34 > li:before { content:'(' counter(awlistcounter3_2, lower-latin) ')'; counter-increment:awlistcounter3_2 }
			.awlist35 { list-style:none; counter-reset:awlistcounter3_2 14 }
			.awlist35 > li:before { content:'(' counter(awlistcounter3_2, lower-latin) ')'; counter-increment:awlistcounter3_2 }
			.awlist36 { list-style:none; counter-reset:awlistcounter3_2 15 }
			.awlist36 > li:before { content:'(' counter(awlistcounter3_2, lower-latin) ')'; counter-increment:awlistcounter3_2 }
			.awlist37 { list-style:none; counter-reset:awlistcounter3_0 5 }
			.awlist37 > li:before { content:counter(awlistcounter3_0) '.'; counter-increment:awlistcounter3_0 }
			.awlist38 { list-style:none; counter-reset:awlistcounter3_1 }
			.awlist38 > li:before { content:'6.' counter(awlistcounter3_1); counter-increment:awlistcounter3_1 }
			.awlist39 { list-style:none; counter-reset:awlistcounter3_1 1 }
			.awlist39 > li:before { content:'6.' counter(awlistcounter3_1); counter-increment:awlistcounter3_1 }
			.awlist40 { list-style:none; counter-reset:awlistcounter3_1 2 }
			.awlist40 > li:before { content:'6.' counter(awlistcounter3_1); counter-increment:awlistcounter3_1 }
			.awlist41 { list-style:none; counter-reset:awlistcounter3_0 6 }
			.awlist41 > li:before { content:counter(awlistcounter3_0) '.'; counter-increment:awlistcounter3_0 }
			.awlist42 { list-style:none; counter-reset:awlistcounter3_0 7 }
			.awlist42 > li:before { content:counter(awlistcounter3_0) '.'; counter-increment:awlistcounter3_0 }
			.awlist43 { list-style:none; counter-reset:awlistcounter3_1 }
			.awlist43 > li:before { content:'8.' counter(awlistcounter3_1); counter-increment:awlistcounter3_1 }
			.awlist44 { list-style:none; counter-reset:awlistcounter3_1 1 }
			.awlist44 > li:before { content:'8.' counter(awlistcounter3_1); counter-increment:awlistcounter3_1 }
			.awlist45 { list-style:none; counter-reset:awlistcounter3_1 2 }
			.awlist45 > li:before { content:'8.' counter(awlistcounter3_1); counter-increment:awlistcounter3_1 }
			.awlist46 { list-style:none; counter-reset:awlistcounter3_1 3 }
			.awlist46 > li:before { content:'8.' counter(awlistcounter3_1); counter-increment:awlistcounter3_1 }
			.awlist47 { list-style:none; counter-reset:awlistcounter3_1 4 }
			.awlist47 > li:before { content:'8.' counter(awlistcounter3_1); counter-increment:awlistcounter3_1 }
			.awlist48 { list-style:none; counter-reset:awlistcounter3_0 8 }
			.awlist48 > li:before { content:counter(awlistcounter3_0) '.'; counter-increment:awlistcounter3_0 }
			.awlist49 { list-style:none; counter-reset:awlistcounter3_0 9 }
			.awlist49 > li:before { content:counter(awlistcounter3_0) '.'; counter-increment:awlistcounter3_0 }
			.awlist50 { list-style:none; counter-reset:awlistcounter3_0 10 }
			.awlist50 > li:before { content:counter(awlistcounter3_0) '.'; counter-increment:awlistcounter3_0 }
			.awlist51 { list-style:none; counter-reset:awlistcounter3_1 }
			.awlist51 > li:before { content:'11.' counter(awlistcounter3_1); counter-increment:awlistcounter3_1 }
			.awlist52 { list-style:none; counter-reset:awlistcounter3_1 1 }
			.awlist52 > li:before { content:'11.' counter(awlistcounter3_1); counter-increment:awlistcounter3_1 }
			.awlist53 { list-style:none; counter-reset:awlistcounter3_1 2 }
			.awlist53 > li:before { content:'11.' counter(awlistcounter3_1); counter-increment:awlistcounter3_1 }
			.awlist54 { list-style:none; counter-reset:awlistcounter3_0 11 }
			.awlist54 > li:before { content:counter(awlistcounter3_0) '.'; counter-increment:awlistcounter3_0 }
			.awlist55 { list-style:none; counter-reset:awlistcounter3_1 }
			.awlist55 > li:before { content:'12.' counter(awlistcounter3_1); counter-increment:awlistcounter3_1 }
			.awlist56 { list-style:none; counter-reset:awlistcounter3_1 1 }
			.awlist56 > li:before { content:'12.' counter(awlistcounter3_1); counter-increment:awlistcounter3_1 }
			.awlist57 { list-style:none; counter-reset:awlistcounter3_1 2 }
			.awlist57 > li:before { content:'12.' counter(awlistcounter3_1); counter-increment:awlistcounter3_1 }
			.awlist58 { list-style:none; counter-reset:awlistcounter3_0 12 }
			.awlist58 > li:before { content:counter(awlistcounter3_0) '.'; counter-increment:awlistcounter3_0 }
			.awlist59 { list-style:none; counter-reset:awlistcounter3_1 }
			.awlist59 > li:before { content:'13.' counter(awlistcounter3_1); counter-increment:awlistcounter3_1 }
			.awlist60 { list-style:none; counter-reset:awlistcounter3_1 1 }
			.awlist60 > li:before { content:'13.' counter(awlistcounter3_1); counter-increment:awlistcounter3_1 }
			.awlist61 { list-style:none; counter-reset:awlistcounter3_1 2 }
			.awlist61 > li:before { content:'13.' counter(awlistcounter3_1); counter-increment:awlistcounter3_1 }
			.awlist62 { list-style:none; counter-reset:awlistcounter3_0 13 }
			.awlist62 > li:before { content:counter(awlistcounter3_0) '.'; counter-increment:awlistcounter3_0 }
			.awlist63 { list-style:none; counter-reset:awlistcounter3_1 }
			.awlist63 > li:before { content:'14.' counter(awlistcounter3_1); counter-increment:awlistcounter3_1 }
			.awlist64 { list-style:none; counter-reset:awlistcounter3_1 1 }
			.awlist64 > li:before { content:'14.' counter(awlistcounter3_1); counter-increment:awlistcounter3_1 }
			.awlist65 { list-style:none; counter-reset:awlistcounter3_1 2 }
			.awlist65 > li:before { content:'14.' counter(awlistcounter3_1); counter-increment:awlistcounter3_1 }
			.awlist66 { list-style:none; counter-reset:awlistcounter3_1 3 }
			.awlist66 > li:before { content:'14.' counter(awlistcounter3_1); counter-increment:awlistcounter3_1 }
			.awlist67 { list-style:none; counter-reset:awlistcounter3_1 4 }
			.awlist67 > li:before { content:'14.' counter(awlistcounter3_1); counter-increment:awlistcounter3_1 }
			.awlist68 { list-style:none; counter-reset:awlistcounter3_1 5 }
			.awlist68 > li:before { content:'14.' counter(awlistcounter3_1); counter-increment:awlistcounter3_1 }
			.awlist69 { list-style:none; counter-reset:awlistcounter3_1 6 }
			.awlist69 > li:before { content:'14.' counter(awlistcounter3_1); counter-increment:awlistcounter3_1 }
			.awlist70 { list-style:none; counter-reset:awlistcounter3_1 7 }
			.awlist70 > li:before { content:'14.' counter(awlistcounter3_1); counter-increment:awlistcounter3_1 }
			.awlist71 { list-style:none; counter-reset:awlistcounter3_1 8 }
			.awlist71 > li:before { content:'14.' counter(awlistcounter3_1); counter-increment:awlistcounter3_1 }
			.awlist72 { list-style:none; counter-reset:awlistcounter3_1 9 }
			.awlist72 > li:before { content:'14.' counter(awlistcounter3_1); counter-increment:awlistcounter3_1 }
@media (max-width: 900px) { 
img { 
   max-width: 100%;
   height: auto;
}

.table-container {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
}

table {
    width: 100%;
    border-collapse: collapse;
}

td, th {
    padding: 8px;
    text-align: left;
    border: 1px solid #ddd;
}
}	