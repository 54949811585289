


.SettingsHeader {
    font-size: 24px;
    color: #c1feaf;
    text-align: left;
}
.TextFieldLabel {
    color: #829aff !important;
    text-align: left;
}
.EditProfileButton {
    width: 250px;
    background-color: #c1feaf;
    padding: 10px 0px;
    border-radius: 10px;
    cursor: pointer;
}