.App {
  text-align: center;
  background-color: white;
  color: black;
  min-height: 100vh;
  display: block;
  position: relative;
  padding-bottom: 100px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  min-height: 5vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-size: calc(10px + 1vmin);
  color: black;
  padding: 40px 40px;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.glowing-orb {
  width: 20px;
  height: 20px;
  background-color: rgba(190, 254, 166, .95);
  border-radius: 50%;
  box-shadow: 0 0 8px 3px rgba(190, 254, 166, 0.8);
  filter: blur(5px);
  animation: glow 3s infinite;
  margin: 0 auto;
  margin-bottom: 30px;
  margin-top: 30px;
}
@keyframes glow {
  0%, 100% {
    box-shadow: 0 0 10px 5px rgba(190, 254, 166, 0.7);
    filter: blur(1px);
  }
  50% {
    box-shadow: 0 0 25px 10px rgba(190, 254, 166, 0.9);
    filter: blur(2px);
  }
}


@media screen and (max-width: 599px){
  .rnf-notification-feed-popover {
    position: fixed !important;
    top: 56px !important;
    left: 50% !important;
    transform: translateX(-50%) !important;
  }
}

@media screen and (min-width: 600px) {
  .rnf-notification-feed-popover {
    position: fixed !important;
    height: 50% !important;
    top: 50% !important;
    right: 0 !important;
    transform: none !important;
  }
}