

@media screen and (max-width: 899px) {
    .EventTypeContainer {
        width: 331px;
        align-items: center;
    }
    
}

@media screen and (min-width: 900px) {
    .EventTypeContainer {
        width: 100%;
    }
    
}


.EventTypeDynamicContainer {
    cursor: pointer;
    width: 100% !important;
    padding: 15px;
    align-items: center;
    justify-content: space-between;
    background-color: #00008f;
    border-radius: 10px;
    border: 1px solid #7792e4;
}
.EventTypeDynamicTextContainer {
    max-width: 80%;
}
.EventTypeDynamicName {
    font-size: 16px;
    color: #dde4f8;
    text-align: left;
    font-weight: 700;
}
.EventTypeDynamicDescription {
    font-size: 14px;
    color: #dde4f8;
    text-align: left;
}
.EventTypeDynamicIcon {
    color: #dde4f8;
    transform: scale(2.5);
    position: relative;
    right: 10%;
}

.EventTypeFundContainer {
    cursor: pointer;
    width: 100% !important;
    padding: 15px;
    align-items: center;
    justify-content: space-between;
    background-color: #81620e;
    border-radius: 10px;
    border: 1px solid #e8b321;
}
.EventTypeFundTextContainer {
    max-width: 80%;
}
.EventTypeFundName {
    font-size: 16px;
    color: #f7e6b5;
    text-align: left;
    font-weight: 700;
}
.EventTypeFundDescription {
    font-size: 14px;
    color: #f7e6b5;
    text-align: left;
}
.EventTypeFundIcon {
    color: #f7e6b5;
    transform: scale(2.5);
    position: relative;
    right: 10%;
}

.EventTypeGeneralFundContainer {
    cursor: pointer;
    width: 100% !important;
    padding: 15px;
    align-items: center;
    justify-content: space-between;
    background-color: #2f285e;
    border-radius: 10px;
    border: 1px solid #7568d8;
}
.EventTypeGeneralFundTextContainer {
    max-width: 80%;
}
.EventTypeGeneralFundName {
    font-size: 16px;
    color: #fbf5ff;
    text-align: left;
    font-weight: 700;
}
.EventTypeGeneralFundDescription {
    font-size: 14px;
    color: #fbf5ff;
    text-align: left;
}
.EventTypeGeneralFundIcon {
    color: #fbf5ff;
    transform: scale(2.5);
    position: relative;
    right: 10%;
}

.EventTypeStaticContainer {
    cursor: pointer;
    width: 100% !important;
    padding: 15px;
    align-items: center;
    justify-content: space-between;
    background-color: #113b02;
    border-radius: 10px;
    border: 1px solid #83c761;
}
.EventTypeStaticTextContainer {
    max-width: 80%;
}
.EventTypeStaticName {
    font-size: 16px;
    color: #c1feaf;
    text-align: left;
    font-weight: 700;
}
.EventTypeStaticDescription {
    font-size: 14px;
    color: #c1feaf;
    text-align: left;
}
.EventTypeStaticIcon {
    color: #c1feaf;
    transform: scale(2.5);
    position: relative;
    right: 10%;
}

.EventTypeGreyContainer {
    cursor: pointer;
    width: 100% !important;
    padding: 15px;
    align-items: center;
    justify-content: space-between;
    background-color: #202020;
    border-radius: 10px;
    border: 1px solid #1a1a1a;
}
.EventTypeGreyTextContainer {
    max-width: 80%;
}
.EventTypeGreyName {
    font-size: 16px;
    color: #adadad;
    text-align: left;
    font-weight: 700;
}
.EventTypeGreyDescription {
    font-size: 14px;
    color: #adadad;
    text-align: left;
}
.EventTypeGreyIcon {
    color: #adadad;
    transform: scale(2.5);
    position: relative;
    right: 10%;
}

