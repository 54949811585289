
.VectorQuestionDropdownBox {
    color: rgba(215, 215, 215, 1);
    border: 1px solid rgba(67, 67, 67, 1);
}
.ContactUsComponentTopStack {
    text-align: left;
    color: rgba(215,215,215,1);    
}
.FAQItem2Header {
    color: rgba(215,215,215,1); 
    padding-bottom: 8px;
}

/* less than md breakpoint (mobile) */
@media screen and (max-width: 899px) {

    .FAQHeader {
        font-size: 20px;
        color: #c1feaf;
        margin-bottom: 10px;
    }
    /* VectorQuestionBit */
    .VectorQuestionBitIcon {
        width: 12px;
        height: 12px;
    }
    .VectorQuestionBitHeading {
        font-size: 12px;
        
    }
    .VectorQuestionBitBody {
        font-size: 10px;
    }

    /* VectorQuestionDropdown */
    .VectorQuestionDropdownBox {
        padding: 0;
    }
    .VectorQuestionDropdownButton {
        padding: 5px;
    }
    .VectorQuestionDropdownIcon {
        width: 12px;
        height: 12px;
    }
    .VectorQuestionDropdownHeading {
        font-size: 14px;
        position: relative;
        right: 40px;
    }
    .VectorQuestionDropdownBody {
        font-size: 12px;
        padding: 10px 20px 0px 20px;
        color: #a2a3a9;
    }
    .VectorQuestionDropdownBodyLine  {
        text-align: left;
    }
    .VectorQuestionDropdownBodyLine::after {
        content: "\A";
        white-space: pre;
        display: block;
        
    }

    /* ContactUsComponent */
    .ContactUsComponenth1 {
        font-size: 20px;
    }
    .ContactUsComponenth2 {
        font-size: 12px;
    }
    .ContactUsComponenth3 {
        font-size: 10px;
    }
    .ContactUsComponentTopStack .ContactUsComponentButton {
        color: rgba(46, 46, 46, 1);
        background-color: rgba(193, 254, 175, 1);
        text-transform: capitalize;
        width: 33%;
        padding: 0;
    }
    .ContactUsComponentButton .ContactUsComponentButtonText {
        font-size: 8px;
    
    }
    .ContactUsComponentButton .ContactUsComponentButtonIcon {
        width: 10px;
        height: 10px;
    }

    .FAQItem2Header {
        font-size: 10px;
    }
    
}

/* less than lg breakpoint (tablet) */
@media screen and (max-width: 1199px) and (min-width: 900px) {

    .FAQHeader {
        font-size: 30px;
        color: #c1feaf;
    }
    /* VectorQuestionBit */
    .VectorQuestionBitIcon {
        width: 24px;
        height: 24px;
    }
    .VectorQuestionBitHeading {
        font-size: 20px;
        
    }
    .VectorQuestionBitBody {
        font-size: 108x;
    }

    /* VectorQuestionDropdown */
    .VectorQuestionDropdownBox {
        padding: 0;
    }
    .VectorQuestionDropdownButton {
        padding: 4;
    }
    .VectorQuestionDropdownIcon {
        width: 30px;
        height: 30px;
        padding-left: 10px;
    }
    .VectorQuestionDropdownHeading {
        font-size: 30px;
        /* position: relative;
        right: 5px; */
        padding: 10px;
    }
    .VectorQuestionDropdownBody {
        font-size: 20px;
        padding: 10px 30px 0px 30px;
        color: #a2a3a9;
    }
    .VectorQuestionDropdownBodyLine  {
        text-align: left;
    }
    .VectorQuestionDropdownBodyLine::after {
        content: "\A";
        white-space: pre;
        display: block;
        
    }

    /* ContactUsComponent */
    .ContactUsComponenth1 {
        font-size: 55px;
    }
    .ContactUsComponenth2 {
        font-size: 18px;
    }
    .ContactUsComponenth3 {
        font-size: 22px;
    }
    .ContactUsComponentTopStack .ContactUsComponentButton {
        color: rgba(46, 46, 46, 1);
        background-color: rgba(193, 254, 175, 1);
        text-transform: capitalize;
        width: 33%;
        padding: 1;
    }
    .ContactUsComponentButton .ContactUsComponentButtonText {
        font-size: 20px;
    
    }
    .ContactUsComponentButton .ContactUsComponentButtonIcon {
        width: 20px;
        height: 20px;
    }

    .FAQItem2Header {
        font-size: 15px;
    }
    
}

/* greater than lg breakpoint (desktop) */
@media screen and (min-width: 1200px) {

    .FAQHeader {
        font-size: 30px;
        color: #c1feaf;
    }
    /* VectorQuestionBit */
    .VectorQuestionBitIcon {
        width: 28px;
        height: 28px;
    }
    .VectorQuestionBitHeading {
        font-size: 24px;
        
    }
    .VectorQuestionBitBody {
        font-size: 20px;
        color: #a2a3a9;
    }

    /* VectorQuestionDropdown */
    .VectorQuestionDropdownBox {
        padding: 2;
    }
    .VectorQuestionDropdownButton {
        padding: 4;
    }
    .VectorQuestionDropdownIcon {
        width: 24px;
        height: 24px;
    }
    .VectorQuestionDropdownHeading {
        font-size: 20px;
        position: relative;
        right: 15px;
    }
    .VectorQuestionDropdownBody {
        font-size: 20px;
        padding: 10px 30px 0px 30px;
        color: #a2a3a9;
    }
    .VectorQuestionDropdownBodyLine  {
        text-align: left;
    }
    .VectorQuestionDropdownBodyLine::after {
        content: "\A";
        white-space: pre;
        display: block;
        
    }

    /* ContactUsComponent */
    .ContactUsComponenth1 {
        font-size: 50px;
    }
    .ContactUsComponenth2 {
        font-size: 18px;
    }
    .ContactUsComponenth3 {
        font-size: 22px;
    }
    .ContactUsComponentTopStack .ContactUsComponentButton {
        color: rgba(46, 46, 46, 1);
        background-color: rgba(193, 254, 175, 1);
        text-transform: capitalize;
        width: 40%;
        padding: 1;
    }
    .ContactUsComponentButton .ContactUsComponentButtonText {
        font-size: 20px;
    
    }
    .ContactUsComponentButton .ContactUsComponentButtonIcon {
        width: 20px;
        height: 20px;
    }

    .FAQItem2Header {
        font-size: 15px;
    }
    
}


