
@media screen and (min-width: 1200px) {
    .AboutUsHeader {
        color: #c1feaf;
        font-size: 40px;
        text-align: center;
        font-weight: 700;
    }
    .AboutUsSubHeader {
        text-align: left;
        font-size: 25px;
        color: #f1f1f1;
    }
    .AboutUsText {
        text-align: left;
        font-size: 20px;
        color: #a2a3a9;
        line-height: 30px;
    }
    .AboutUsTextLine::after {
        content: "\A";
        white-space: pre;
        display: block;
    }
    .AboutUsBottomText {
        padding: 20px 0px;
        text-align: center;
        font-size: 30px;
        color: #c1feaf;
        line-height: 30px;
        font-weight: 700;
    }
}

@media screen and (max-width: 1199px) and (min-width: 900px) {
    .AboutUsHeader {
        color: #c1feaf;
        font-size: 40px;
        text-align: center;
        font-weight: 700;
    }
    .AboutUsSubHeader {
        text-align: left;
        font-size: 25px;
        color: #f1f1f1;
    }
    .AboutUsText {
        text-align: left;
        font-size: 20px;
        color: #a2a3a9;
        line-height: 30px;
    }
    .AboutUsTextLine::after {
        content: "\A";
        white-space: pre;
        display: block;
    }
    .AboutUsBottomText {
        padding: 20px 0px;
        text-align: center;
        font-size: 30px;
        color: #c1feaf;
        line-height: 30px;
        font-weight: 700;
    }
}

@media screen and (max-width: 899px) {
    .AboutUsHeader {
        color: #c1feaf;
        font-size: 30px;
        text-align: center;
        font-weight: 700;
    }
    .AboutUsSubHeader {
        text-align: left;
        font-size: 20px;
        color: #f1f1f1;
    }
    .AboutUsText {
        text-align: left;
        font-size: 18px;
        color: #a2a3a9;
        line-height: 25px;
    }
    .AboutUsTextLine::after {
        content: "\A";
        white-space: pre;
        display: block;
    }
    .AboutUsBottomText {
        padding: 20px 0px;
        text-align: center;
        font-size: 30px;
        color: #c1feaf;
        line-height: 30px;
        font-weight: 700;
    }
}