

@media screen and (max-width: 899px) {
    .HowItWorksHeader {
        font-size: 30px;
        color: #c1feaf;
        text-align: center;
        margin-bottom: 10px !important;
        font-weight: 700;
    }
    .HowItWorksSubHeader {
        font-size: 20px;
        color: #f1f1f1;
        text-align: left;
        margin-bottom: 20px;
    }
    .HowItWorksText {
        font-size: 18px;
        color: #a2a3a9;
        text-align: left;
    }
    .HowItWorksTextLine::after {
        content: "\A";
        white-space: pre;
        display: block;
    }
    .HowItWorksListHeader {
        font-size: 18px;
        color: #f1f1f1;
        text-align: left;
    }
    .HowItWorksListHeaderAlt {
        font-size: 18px;
        color: #c1feaf;
        text-align: left;
    }
    .HowItWorksListHeader::after {
        content: "\A";
        white-space: pre;
        display: block;
    }
    .HowItWorksTextList::after {
        content: "";
        white-space: pre;
        display: block;
    }
}

@media screen and (max-width: 1199px) and (min-width: 900px) {
    .HowItWorksHeader {
        font-size: 40px;
        color: #c1feaf;
        text-align: center;
        margin-bottom: 10px !important;
        font-weight: 700;
    }
    .HowItWorksSubHeader {
        font-size: 30px;
        color: #f1f1f1;
        text-align: left;
        margin-bottom: 20px;
    }
    .HowItWorksText {
        font-size: 20px;
        color: #a2a3a9;
        text-align: left;
    }
    .HowItWorksTextLine::after {
        content: "\A";
        white-space: pre;
        display: block;
    }
    .HowItWorksListHeader {
        font-size: 20px;
        color: #f1f1f1;
        text-align: left;
    }
    .HowItWorksListHeaderAlt {
        font-size: 20px;
        color: #c1feaf;
        text-align: left;
    }
    .HowItWorksListHeader::after {
        content: "\A";
        white-space: pre;
        display: block;
    }
    .HowItWorksTextList::after {
        content: "";
        white-space: pre;
        display: block;
    }
}


@media screen and (min-width: 1200px) {
    .HowItWorksHeader {
        font-size: 40px;
        color: #c1feaf;
        text-align: center;
        margin-bottom: 10px !important;
        font-weight: 700;
    }
    .HowItWorksSubHeader {
        font-size: 30px;
        color: #f1f1f1;
        text-align: left;
        margin-bottom: 20px;
    }
    .HowItWorksText {
        font-size: 20px;
        color: #a2a3a9;
        text-align: left;
    }
    .HowItWorksTextLine::after {
        content: "\A";
        white-space: pre;
        display: block;
    }
    .HowItWorksListHeader {
        font-size: 20px;
        color: #f1f1f1;
        text-align: left;
    }
    .HowItWorksListHeaderAlt {
        font-size: 20px;
        color: #c1feaf;
        text-align: left;
    }
    .HowItWorksListHeader::after {
        content: "\A";
        white-space: pre;
        display: block;
    }
    .HowItWorksTextList::after {
        content: "";
        white-space: pre;
        display: block;
    }
}