

.CreateEventInfo {
    width: 100%
}
.CreateEventInfoLabel {
    color: #829aff;
    font-size: 10px;
    font-weight: 400;
    text-align: left;
}

@media screen and (max-width: 899px) {
    
    .CreateEventInfoLabel {
        color: #829aff;
        font-size: 16px;
        font-weight: 600;
        text-align: left;
    }
    
}

@media screen and (min-width: 900px) {
    
    .CreateEventInfoLabel {
        color: #829aff;
        font-size: 18px;
        font-weight: 600;
        text-align: left;
    }
    
}